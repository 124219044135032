import React, { useEffect, useState } from 'react';

import { selectors } from './model';
import { UserStatus } from './types';
import { CheckEligibilityForm } from './CheckEligibilityForm';
import { CheckEligibilityFail } from './CheckEligibilityFail';
import { CheckEligibilityOtp } from './CheckEligibilityOtp';
import { useHistory } from 'react-router-dom';
import { OnLoadModal } from '@features/CheckEligibilityForm/OnLoadModal';

/**
 * Check eligibility container
 * @returns React.FC
 * */
export const CheckEligibilityContainer: React.FC = () => {
  const userStatus = selectors.useUserStatus();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (userStatus === UserStatus.CONFIRMED) {
      history.push('/registration');
    }
  }, [userStatus, history]);

  return (
    <>
      {userStatus === UserStatus.NEW && <CheckEligibilityForm />}
      {userStatus === UserStatus.CHECKED && <CheckEligibilityOtp />}
      {userStatus === UserStatus.FAILED && <CheckEligibilityFail />}
      <OnLoadModal
        // isOpen={isModalOpen && userStatus === UserStatus.NEW}
        isOpen={false}
        setIsOpen={setIsModalOpen}
      />
    </>
  );
};
