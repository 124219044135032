import { useEffect } from 'react';
import { userModel } from '@entities/user';
import { settingsModel } from '@entities/settings';
import { selectors, actions } from './model';
import { useActions } from '@common/store-utils/hooks';
import { dateDiff, isUserUnder30 } from '@features/Registration/utils';

export const useIsUserUnder30 = () => {
  const user = selectors.usePersonalInfoData();
  const period = '2024-12-15T10:00:00.000Z';
  const periodReal = settingsModel.selectors.useRegisterPeriodStartDate();
  const { prefillUserPersonalData } = useActions(actions);
  const { getSettings } = useActions(settingsModel.actions);
  const { sendMail } = useActions(settingsModel.actions);

  useEffect(() => {
    if (!user) {
      prefillUserPersonalData();
    }
  }, []);

  useEffect(() => {
    if (!periodReal) {
      getSettings();
    }
  }, []);

  // if (!isUserUnder30(periodReal, user?.birthday)) {
  //   sendMail(`
  //   test v.3<br />
  //   ${navigator.userAgent}<br />
  //   User is above 30,<br />
  //   Static result - ${isUserUnder30(period, user?.birthday)},<br />
  //   BDay - ${user?.birthday},<br />
  //   RegOpenDay(static) - ${period},<br />
  //   RegOpenDay(real) - ${periodReal},<br />
  //   Diff - ${dateDiff(user?.birthday, period).years} years<br />
  //   user: ${JSON.stringify(user)}`);
  // }

  return isUserUnder30(period, user?.birthday);
};
