import React from 'react';
import * as CUI from '@CUI';
import { Modal } from '@common/perach-ui';
import { useActions } from '@common/store-utils/hooks';
import { useTranslation } from 'react-i18next';

import { actions, selectors } from '../model';

type props = {};

export const ResultModal: React.FC<props> = () => {
  const { t } = useTranslation();
  const { submitRequestConfirm, setResultModalOpen } = useActions(actions);
  const validationErrors = selectors.useValidationError();
  const isOpen = selectors.useIsResultModalOpen();
  const isSuccess = !validationErrors?.length;

  const handleClose = () => {
    if (isSuccess) {
      submitRequestConfirm();
    } else {
      setResultModalOpen(false);
    }
  };

  return (
    <Modal
      isModalOpen={isOpen || false}
      header={t('DONT_FORGET_TO_SUBMIT_MODAL.HEADER')}
      modalBody={
        <CUI.Div _modalBodyContainer>
          <CUI.Span _modalBodyHeader _mt24>
            {isSuccess ? (
              <>
                {`הבקשה הוגשה בהצלחה!`}
                <br />
                {`הבקשה הוגשה ותעבור בדיקה לפי תנאי הסף, הקריטריונים ונהלי המלגה.`}
                <br />
                {`אם יידרש תיקון, הבקשה תחזור לתיקון המסמכים, ויעמדו לרשותך 10 ימים מרגע השליחה לתיקונם. הודעה נפרדת תשלח במייל וב-SMS עם הוראות מתאימות באיזור האישי. 
שימו לב, ניתן לתקן את הבקשה פעם אחת בלבד, ומסמך שתינתן האפשרות לתקן אותו- לא ניתן יהא לערער עליו וערעור כזה ידחה על הסף.`}
                <br />
                {`צפי למתן תשובות סופיות במהלך מאי-יוני 2025.`}
              </>
            ) : (
              'הייתה בעיה בהגשת הבקשה שלך '
            )}
            {validationErrors &&
              Array.isArray(validationErrors) &&
              validationErrors.map((e) => (
                <React.Fragment key={CUI.utils.generateId()}>
                  <br />
                  {e}
                </React.Fragment>
              ))}
          </CUI.Span>
          <CUI.Div _inline>
            <CUI.Button
              _btnPrimaryPerach
              _mb16
              _mt24
              _ml16
              onClick={() => {
                handleClose();
              }}
            >
              {t('DONT_FORGET_TO_SUBMIT_MODAL.CLOSE')}
            </CUI.Button>
          </CUI.Div>
        </CUI.Div>
      }
      closeModal={() => {
        handleClose();
      }}
    />
  );
};
