import { DicRecord } from '../../entities/dictionaries';

export enum ResidencyStatusType {
  RESIDENT = 'RESIDENT',
  CITIZEN = 'CITIZEN',
  NONE = 'NONE',
}

export enum CurrentlyStudyingType {
  BACHELOR = 'BACHELOR',
  PREPARATORY = 'PREPARATORY',
  MAHAT = 'MAHAT',
  OTHER = 'NONE',
}

export enum ScholarshipsSelectType {
  MAROM = 'MAROM',
  TENA = 'TENA',
  IRTIKA = 'IRTIKA',
  PERACH = 'PERACH',
  NONE = 'NONE',
}

export enum UserStatus {
  NEW = 'NEW',
  CHECKED = 'CHECKED',
  CONFIRMED = 'CONFIRMED',
  FAILED = 'FAILED',
}

export type CheckEligibilityFormFields = {
  identityNumber: string;
  phone: string;
  password: string;
  passwordConfirmation: string;
  residencyStatus: ResidencyStatusType;
  academicInstitution: DicRecord | null;
  currentlyStudying: CurrentlyStudyingType;
  otherBachelor: boolean;
  eligibleForAssistance: boolean;
  receivedScholarship: boolean;
  receivedScholarshipName: string;
  receivedScholarshipAmount: string;
  scholarships: Record<ScholarshipsSelectType, boolean>;
  notDeserter: boolean;
  termsConfirm: string;
  email: string;
  emailConfirm: string;
};

export type ModelState = {
  formData: CheckEligibilityFormFields;
  status: UserStatus;
  isValid: boolean;
  otpCode: string;
  errors: EligibilityErrors;
};

export type EligibilityErrors = Array<
  | 'DESERTER'
  | 'NOT_CITIZEN'
  | 'CURRENTLY_NOT_BACHELOR'
  | 'DESERVE_ASSISTANCE'
  | 'OTHER_BACHELOR'
>;

export type CheckEligibilityResponse = {
  id?: string;
  name?: string;
  user_status?: string;
  user_id?: string;
  process_id?: string;
  username?: string;
  session?: string;
  error?: {
    message?: any;
    errors?: EligibilityErrors;
    statusCode?: number;
  };
};

export type CheckEligibilityRequest = {
  identity_number: string;
  phone: string;
  terms: number;
  password: string;
  residency_status: ResidencyStatusType;
  academic_institution_id: number;
  currently_studying: string;
  other_bachelor?: boolean;
  eligible_for_assistance?: boolean;
  scholarships: ScholarshipsSelectType[];
  not_deserter: boolean;
  other_scholarship: boolean;
  other_scholarship_name: string;
  other_scholarship_amount: number | '';
  email: string;
};

export const formDataDefaultValues: CheckEligibilityFormFields = {
  identityNumber: '',
  phone: '',
  password: '',
  passwordConfirmation: '',
  email: '',
  emailConfirm: '',
  residencyStatus: ResidencyStatusType.CITIZEN,
  academicInstitution: null,
  currentlyStudying: CurrentlyStudyingType.BACHELOR,
  otherBachelor: false,
  eligibleForAssistance: false,
  scholarships: {
    [ScholarshipsSelectType.MAROM]: false,
    [ScholarshipsSelectType.IRTIKA]: false,
    [ScholarshipsSelectType.PERACH]: false,
    [ScholarshipsSelectType.TENA]: false,
    [ScholarshipsSelectType.NONE]: true,
  },
  termsConfirm: '',
  notDeserter: false,
  receivedScholarship: false,
  receivedScholarshipName: '',
  receivedScholarshipAmount: '',
};

export const CheckEligibilityDefaultValues: ModelState = {
  formData: formDataDefaultValues,
  isValid: false,
  otpCode: '',
  status: UserStatus.NEW,
  errors: [],
};
